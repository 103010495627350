import React from 'react';
import Slider from 'react-slick';
import './Shop.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import starterDeckImage from '../images/starter-deck.jpeg';
import fantasyDeckImage from '../images/fantasy-deck.jpeg';
import scifiDeckImage from '../images/scifi-deck.jpeg';
import villainsDeckImage from '../images/villains-deck.jpeg';
import historyDeckImage from '../images/history-deck.jpeg';

const products = [
  {
    name: "AICONS Starter Pack",
    description: "Get rolling with the Starter Pack. Includes a variety of cards from different categories to ignite your imagination.",
    image: starterDeckImage
  },
  {
    name: "Fantasy Deck",
    description: "Add some magic to your collection with the Fantasy Deck. Includes mythical creatures and magical elements.",
    image: fantasyDeckImage
  },
  {
    name: "Sci-Fi Deck",
    description: "Explore the future and outer worlds with the Sci-Fi Deck. Includes aliens, robots, places, and styles of the universe.",
    image: scifiDeckImage
  },
  {
    name: "Villain Deck",
    description: "Embrace the darkness with the Villain Pack. Features evil characters, sinister outfits, and horror-inspired styles.",
    image: villainsDeckImage
  },
  {
    name: "History Deck",
    description: "Travel through time with the History Pack. Features characters and styles from various periods and settings.",
    image: historyDeckImage
  },
];

function ProductCard({ name, description, image }) {
  return (
    <div className="product-card">
      <img src={image} alt={name} className="product-image" />
      <div className="product-info">
        <h3>{name}</h3>
        <p>{description}</p>
        <button className="buy-button">Buy Now</button>
      </div>
    </div>
  );
}

function Shop() {
  const settings = {
    dots: true,
    infinite: true,
    speed: 800,
    slidesToShow: 3,
    slidesToScroll: 2,
    arrows: true,
    draggable: true,
    autoplay: true,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  return (
    <section className="shop">
      <h2>Shop All Products</h2>
      <div className="product-carousel">
        <Slider {...settings}>
          {products.map((product, index) => (
            <ProductCard
              key={index}
              name={product.name}
              description={product.description}
              image={product.image}
            />
          ))}
        </Slider>
      </div>
    </section>
  );
}

export default Shop;