import React from 'react';
import './Footer.css';
import logo from '../images/Logo.svg'; // Adjust the path if necessary
import envelopeIcon from '../images/envelope.svg';

const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <footer className="site-footer">
      <div className="footer-content">
        <div className="logo-container">
          <img src={logo} alt="Aicon Logo" className="footer-logo" />
        </div>
        <div className="company-info">
          <p>Aicon is backed by Greenkeys SL</p>
          <p>La Rambla 73 - Principal</p>
          <p>Barcelona</p>
        </div>
        <div className="contact-link">
          <a href="mailto:aicons.online@gmail.com" aria-label="Send email to aicons.online@gmail.com">
            <img src={envelopeIcon} alt="Email Us" className="envelope-icon" />
          </a>
        </div>
      </div>
      <div className="footer-bottom">
        <p>&copy; {currentYear} Greenkeys SL. All rights reserved.</p>
      </div>
    </footer>
  );
};

export default Footer;