import React from 'react';
import './App.css';
import Header from './components/Header';
import Hero from './components/Hero';
import Introduction from './components/Introduction';
import Shop from './components/Shop'; 
//import Testimonials from './components/Testimonials';
import GamePlay from './components/GamePlay';
import Winners from './components/Winners'; 
import Download from './components/Download'; 
import Footer from './components/Footer'; 

function App() {
  return (
    <div className="App">
      <Header />
      <main>
        <Hero />
        <Introduction />
        <Shop />
       {/*  <Testimonials />*/}
        <GamePlay />
        <Winners />
        <Download />
        <Footer />
        {/* We'll add other sections here as we build them */}
      </main>
    </div>
  );
}

export default App;