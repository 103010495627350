import React from 'react';
import './Introduction.css';

function Introduction() {
  return (
    <section className="introduction">
      <h2>Dive into the exciting world of AICONS: collect cards, create prompts, and see your creations come to life. Ready to get started?</h2>
    </section>
  );
}

export default Introduction;