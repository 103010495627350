import React, { useEffect, useState } from 'react';
import { Link } from 'react-scroll';
import './Hero.css';
import heroImage from '../images/hero-image.jpg';
import logo from '../images/Logo.svg'; // Make sure this path is correct

function Hero() {
  const [offset, setOffset] = useState(0);

  useEffect(() => {
    const handleScroll = () => setOffset(window.pageYOffset);
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <section className="hero">
      <div className="hero-image-container">
        <img src={heroImage} alt="AICONS game setup" className="hero-image" />
        <div className="hero-logo-container">
          <img 
            src={logo} 
            alt="AICONS Logo" 
            className="hero-logo" 
            style={{ transform: `translateY(${offset * 0.8}px)` }}
          />
        </div>
      </div>
      <div className="hero-content">
        <Link to="download" smooth={true} duration={500}>
          <button className="cta-button">Download FREE DECK</button>
        </Link>
      </div>
    </section>
  );
}

export default Hero;