import React, { useState } from 'react';
import './Download.css';

function Download() {
  const [email, setEmail] = useState('');
  const [receiveNews, setReceiveNews] = useState(false);
  const [showPopup, setShowPopup] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    // Here you would typically send the data to a server
    // For this example, we'll just show the popup
    setShowPopup(true);
    // Reset form
    setEmail('');
    setReceiveNews(false);
  };

  return (
    <section id="download" className="download">
      <h2>Fill the form to receive the FREE DECK</h2>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="email">Email:</label>
          <input
            type="email"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label>
            <input
              type="checkbox"
              checked={receiveNews}
              onChange={(e) => setReceiveNews(e.target.checked)}
            />
            Yes, I'd like to receive news about the game!
          </label>
        </div>
        <button type="submit" className="submit-button">Send</button>
      </form>

      <div className="promotional-text">
        <p>
          *Create an awesome image and post it on your social media! Make sure to tag #AICONS in your post, and we'll reward you with a 20% discount code for an expansion deck of your choice. Show us your creativity and level up your game today!
        </p>
      </div>

      {showPopup && (
        <div className="popup">
          <div className="popup-content">
            <button className="close-button" onClick={() => setShowPopup(false)}>X</button>
            <p>Thanks, you will receive shortly an email with the link to download the FREE DECK.</p>
            <p className="have-fun">Have fun!</p>
          </div>
        </div>
      )}
    </section>
  );
}

export default Download;