import React from 'react';
import './GamePlay.css';
import step1 from '../images/step1.png';
import step2 from '../images/step2.png';
import step3 from '../images/step3.png';
import step4 from '../images/step4.png';
import step5 from '../images/step5.png';
import step6 from '../images/step6.png';

const gameplaySteps = [
  {
    title: "AI Generates the Title",
    description: "The companion app's AI generates a unique title for the round, setting an exciting challenge for all players.",
    image: step1
  },
  {
    title: "Pick & Create",
    description: "Draw cards from the deck and strategically use them to build characters that best interpret the game title.",
    image: step2
  },
  {
    title: "Craft Your Vision",
    description: "Assemble your image prompt by selecting outfits, actions, backgrounds, and visual styles from the card deck.",
    image: step3
  },
  {
    title: "Play Dirty",
    description: "Disrupt your opponents' plans and alter the game dynamics playing Special Cards at the right moment.",
    image: step4
  },
  {
    title: "Bring to Life",
    description: "Once all prompts are complete, the AI generates unique and imaginative image character for each player.",
    image: step5
  },
  {
    title: "AI Crowns the Winner",
    description: "The companion app's AI evaluates the images for alignment with the game title and chooses the Winner!",
    image: step6
  }
];

function Gameplay() {
  return (
    <section className="gameplay">
      <h2>How to Play AICONS</h2>
      <div className="gameplay-grid">
        {gameplaySteps.map((step, index) => (
          <div key={index} className="gameplay-card">
            <div className="gameplay-card-inner">
              <div className="gameplay-card-icon">
                <img src={step.image} alt={`Step ${index + 1}`} />
              </div>
              <h3>{index + 1}. {step.title}</h3>
              <p>{step.description}</p>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
}

export default Gameplay;