import React from 'react';
import './Header.css';

function Header() {
  return (
    <header className="site-header">
    <div className="company-name">Greenkeys SL</div>
    </header>
  );
}

export default Header;