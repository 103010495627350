import React from 'react';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './Winners.css';

const winners = [
  {
    text: "Aicons is a hit with everyone from grandparents to grandkids.",
    author: "@sarah_williams87",
    avatar: require('../images/Winners/Avatar-01.png'),
    winningImage: require('../images/Winners/Winner-01.png')
  },
  {
    text: "This game is so different from anything I've ever played. Totally hooked.",
    author: "@browneyedgamer",
    avatar: require('../images/Winners/Avatar-02.png'),
    winningImage: require('../images/Winners/Winner-02.png')
  },
  {
    text: "Perfect game for bonding with the kids and getting those creative juices flowing.",
    author: "@diego.hernandez",
    avatar: require('../images/Winners/Avatar-03.png'),
    winningImage: require('../images/Winners/Winner-03.png')
  },
  {
    text: "I've learned a ton about prompting thanks to this game. It's actually fun to improve my skills.",
    author: "@ladywellz",
    avatar: require('../images/Winners/Avatar-04.png'),
    winningImage: require('../images/Winners/Winner-04.png')
  },
  {
    text: "We love how this game brings generations together. It's a guaranteed laugh for the whole family.",
    author: "@sophiawiler",
    avatar: require('../images/Winners/Avatar-05.png'),
    winningImage: require('../images/Winners/Winner-05.png')
  },
  {
    text: "Game nights are all about this game now. The AI is pure genius.",
    author: "@lucas_hall18",
    avatar: require('../images/Winners/Avatar-06.png'),
    winningImage: require('../images/Winners/Winner-06.png')
  },
  {
    text: "This is the one game everyone in our house can agree on. It's great for all ages and skill levels.",
    author: "@li_wei92",
    avatar: require('../images/Winners/Avatar-07.png'),
    winningImage: require('../images/Winners/Winner-07.png')
  },
  {
    text: "The AI is constantly coming up with new prompts. This game never gets old.",
    author: "@olliharris",
    avatar: require('../images/Winners/Avatar-08.png'),
    winningImage: require('../images/Winners/Winner-08.png')
  },
  {
    text: "We've had so much fun playing this with friends and family of all ages.",
    author: "@aisha_abdul",
    avatar: require('../images/Winners/Avatar-09.png'),
    winningImage: require('../images/Winners/Winner-09.png')
  },
  {
    text: "The conversations this game sparks are the best part. It's a guaranteed laugh every time.",
    author: "@davemartinezart",
    avatar: require('../images/Winners/Avatar-10.png'),
    winningImage: require('../images/Winners/Winner-10.png')
  },
  {
    text: "This game is a must-have for creative people. The AI art is stunning.",
    author: "@miayoung",
    avatar: require('../images/Winners/Avatar-11.png'),
    winningImage: require('../images/Winners/Winner-11.png')
  },
  {
    text: "We used this game for our company retreat and it was a huge hit! It helped break the ice and build camaraderie.",
    author: "@anna_ivanova",
    avatar: require('../images/Winners/Avatar-12.png'),
    winningImage: require('../images/Winners/Winner-12.png')
  },
  {
    text: "This game brings out everyone's competitive and creative sides. It's a total game-changer.",
    author: "@masongreen",
    avatar: require('../images/Winners/Avatar-13.png'),
    winningImage: require('../images/Winners/Winner-13.png')
  },
  {
    text: "The AI art in this game blows my mind. It's like having a personal illustrator.",
    author: "@sophiesdoodles",
    avatar: require('../images/Winners/Avatar-14.png'),
    winningImage: require('../images/Winners/Winner-14.png')
  },
  {
    text: "This game is a great workout for your brain and your imagination.",
    author: "@isabella_w92",
    avatar: require('../images/Winners/Avatar-15.png'),
    winningImage: require('../images/Winners/Winner-15.png')
  },
  {
    text: "The AI-generated titles are hilarious and always catch you off guard.",
    author: "@noah-knows-games",
    avatar: require('../images/Winners/Avatar-16.png'),
    winningImage: require('../images/Winners/Winner-16.png')
  },
  {
    text: "This game is perfect for fostering creativity and teamwork in a fun and engaging way.",
    author: "@maria_gonzalez22",
    avatar: require('../images/Winners/Avatar-17.png'),
    winningImage: require('../images/Winners/Winner-17.png')
  },
  {
    text: "This is hands down my favorite game now. The AI challenges are always on point.",
    author: "@emma.wright",
    avatar: require('../images/Winners/Avatar-18.png'),
    winningImage: require('../images/Winners/Winner-18.png')
  },
  {
    text: "I feel like an artist playing this game. Every round is a totally new adventure!",
    author: "@mikelikesgames",
    avatar: require('../images/Winners/Avatar-19.png'),
    winningImage: require('../images/Winners/Winner-19.png')
  },
  {
    text: "This is the perfect combo of tech and creativity. Everyone needs to try this game.",
    author: "@chrislee",
    avatar: require('../images/Winners/Avatar-20.png'),
    winningImage: require('../images/Winners/Winner-20.png')
  },
  {
    text: "This game is a fantastic addition to any classroom. It promotes critical thinking, communication, and collaboration.",
    author: "@ayşe_yilmaz",
    avatar: require('../images/Winners/Avatar-21.png'),
    winningImage: require('../images/Winners/Winner-21.png')
  },
  {
    text: "Game nights with this are non-stop laughter and creative chaos.",
    author: "@dannycentury",
    avatar: require('../images/Winners/Avatar-22.png'),
    winningImage: require('../images/Winners/Winner-22.png')
  },
  {
    text: "My students absolutely love this game! It's a fun and engaging way to learn about AI and creativity.",
    author: "@pablo_rodriguez",
    avatar: require('../images/Winners/Avatar-23.png'),
    winningImage: require('../images/Winners/Winner-23.png')
  },
  {
    text: "The way they mixed AI with classic card game mechanics is brilliant. Can't recommend this enough.",
    author: "@benjaminking42",
    avatar: require('../images/Winners/Avatar-24.png'),
    winningImage: require('../images/Winners/Winner-24.png')
  },
  {
    text: "The app that goes with this game is so slick. Makes it even better.",
    author: "@emmy_t33",
    avatar: require('../images/Winners/Avatar-25.png'),
    winningImage: require('../images/Winners/Winner-25.png')
  },
  {
    text: "We've used this game in our after-school program and it's been a huge success. The kids are always asking for more!",
    author: "@fatimali",
    avatar: require('../images/Winners/Avatar-26.png'),
    winningImage: require('../images/Winners/Winner-26.png')
  },
  {
    text: "The AI in this is next-level. The ideas and images it comes up with are just chef's kiss.",
    author: "@thelauradavis",
    avatar: require('../images/Winners/Avatar-27.png'),
    winningImage: require('../images/Winners/Winner-27.png')
  },
  {
    text: "This game is a great tool for sparking imagination and encouraging students to express themselves creatively.",
    author: "@elena_petrova",
    avatar: require('../images/Winners/Avatar-28.png'),
    winningImage: require('../images/Winners/Winner-28.png')
  },
  {
    text: "The whole family is obsessed with this game. Fun, educational, and always gets us talking.",
    author: "@avathompson",
    avatar: require('../images/Winners/Avatar-29.png'),
    winningImage: require('../images/Winners/Winner-29.png')
  },
  {
    text: "This is the most fun I've had with a card game in ages! The AI challenges are seriously creative.",
    author: "@anna_smith2022",
    avatar: require('../images/Winners/Avatar-30.png'),
    winningImage: require('../images/Winners/Winner-30.png')
  }
];

function Winners() {
  const settings = {
    dots: true,
    infinite: true,
    speed: 800,
    slidesToShow: 3,
    slidesToScroll: 2,
    autoplay: true,
    autoplaySpeed: 3000,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  return (
    <section className="winners">
        <h1 className="winners-title">Winners of the Week*</h1>
      <Slider {...settings}>
        {winners.map((winner, index) => (
          <div key={index} className="winner-item">
            <img src={winner.winningImage} alt={`Winning submission by ${winner.author}`} className="winning-image" />
            <div className="winner-content">
              <img src={winner.avatar} alt={winner.author} className="winner-avatar" />
              <div className="winner-text-content">
                <p className="winner-text">{winner.text}</p>
                <p className="winner-author">{winner.author}</p>
              </div>
            </div>
          </div>
        ))}
      </Slider>
    </section>
  );
}

export default Winners;